<template>
  <div class="input-area">
    <label class="input-label" :for="id">{{ label }}</label>
    <b-form-datepicker
      v-if="type === 'date'"
      :class="{
        error: requiredMessage,
        'input-content': true,
        date: type === 'date',
      }"
      :id="id"
      :name="name"
      v-model="inputValue"
      locale="pt-BR"
      hideHeader
      :placeholder="placeholder"
      :date-format-options="{
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }"
      right
      icon="calendar"
    ></b-form-datepicker>
    <input
      v-else
      :class="{
        error: requiredMessage,
        'input-content': true,
        date: type === 'date',
      }"
      :id="id"
      :name="name"
      :placeholder="placeholder"
      :type="type"
      v-model="inputValue"
      :disabled="disabled"
    />
    <span v-if="requiredMessage" class="error-message">{{
      requiredMessage
    }}</span>
  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: String,
    id: String,
    index: Number,
    name: String,
    placeholder: String,
    label: String,
    type: {
      type: String,
      default: "text",
    },
    requiredMessage: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue;
    },
    inputValue(newInputValue) {
      this.$emit("input", newInputValue, this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-area {
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 4px;
}

.input-label {
  font-size: 14px;
  font-weight: 600;
  color: $neutral-95;
}

.input-content {
  width: 100%;
  padding: 9px 16px;

  border: 1px solid $neutral-50;
  border-radius: 6px;

  color: $neutral-95;

  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
  transition: all 0.3s ease;

  outline: none;

  &.error {
    border-color: $red;
  }

  &.date {
    display: flex;
    flex-direction: row-reverse;

    padding: 2px 0 2px 6px;
  }
}

.error-message {
  position: absolute;

  bottom: -16px;

  font-size: 11px;
  color: $red;
}
</style>
