<template>
  <div>
    <div class="container-items-area" v-if="dataRegister.fundo_investimento">
      <div class="row gap-3">
        <div class="col-3 d-flex justify-content-start">
          <CheckboxGroup
            :options="tipo_empreendimento"
            v-model="
              dataRegister.fundo_investimento.perfil_empreendimentos
                .tipo_empreendimento
            "
            :class="'d-flex flex-column gap-1'"
            :title="'Tipo de empreendimento'"
            :requiredMessage="isRequiredMessages.tipo_empreendimento"
          />
        </div>
        <div class="col-3 d-flex justify-content-start">
          <CheckboxGroup
            :options="segmento_atuacao"
            v-model="
              dataRegister.fundo_investimento.perfil_empreendimentos
                .segmento_atuacao
            "
            :class="'d-flex flex-column gap-1'"
            :title="'Segmento de atuação'"
            :requiredMessage="isRequiredMessages.segmento_atuacao"
          />
        </div>
      </div>
      <h3 class="content-title">Porte da obra</h3>
      <div class="row gap-3 mb-4">
        <div class="col-3 d-flex justify-content-start">
          <Select
            :id="'porte_obra'"
            :name="'porte_obra'"
            :label="'Tamanho'"
            :options="porte_obraOptions"
            :value="
              dataRegister.fundo_investimento.perfil_empreendimentos.porte_obra
                .tamanho[0]
            "
            @input="updatePorteObraOption"
            :requiredMessage="isRequiredMessages.tamanho"
          ></Select>
        </div>
        <div class="col-3 d-flex justify-content-start">
          <Select
            :id="'num_unidades'"
            :name="'num_unidades'"
            :label="'N˚ de unidades'"
            :options="num_unidadesOptions"
            :value="
              dataRegister.fundo_investimento.perfil_empreendimentos.porte_obra
                .num_unidades[0]
            "
            @input="updateNumUnidadesOption"
            :requiredMessage="isRequiredMessages.num_unidades"
          ></Select>
        </div>
      </div>
      <h3 class="content-title">Região de atuação</h3>
      <RegionComponent
        ref="regionRef"
        :valuesRegions="
          dataRegister.fundo_investimento.perfil_empreendimentos.regiao_atuacao
        "
      />
    </div>
    <div class="container-items-area" v-else-if="dataRegister.incorporadora">
      <div class="row gap-3">
        <div class="col-3 d-flex justify-content-start">
          <CheckboxGroup
            :options="tipo_empreendimento"
            v-model="
              dataRegister.incorporadora.perfil_empreendimentos
                .tipo_empreendimento
            "
            :class="'d-flex flex-column gap-1'"
            :title="'Tipo de empreendimento'"
            :requiredMessage="isRequiredMessages.tipo_empreendimento"
          />
        </div>
        <div class="col-3 d-flex justify-content-start">
          <CheckboxGroup
            :options="segmento_atuacao"
            v-model="
              dataRegister.incorporadora.perfil_empreendimentos.segmento_atuacao
            "
            :class="'d-flex flex-column gap-1'"
            :title="'Segmento de atuação'"
            :requiredMessage="isRequiredMessages.segmento_atuacao"
          />
        </div>
      </div>
      <h3 class="content-title">Porte da obra</h3>
      <div class="row gap-3 mb-4">
        <div class="col-3 d-flex justify-content-start">
          <Select
            :id="'porte_obra'"
            :name="'porte_obra'"
            :label="'Tamanho'"
            :options="porte_obraOptions"
            :value="
              dataRegister.incorporadora.perfil_empreendimentos.porte_obra
                .tamanho[0]
            "
            @input="updatePorteObraOption"
            :requiredMessage="isRequiredMessages.tamanho"
          ></Select>
        </div>
        <div class="col-3 d-flex justify-content-start">
          <Select
            :id="'num_unidades'"
            :name="'num_unidades'"
            :label="'N˚ de unidades'"
            :options="num_unidadesOptions"
            :value="
              dataRegister.incorporadora.perfil_empreendimentos.porte_obra
                .num_unidades[0]
            "
            @input="updateNumUnidadesOption"
            :requiredMessage="isRequiredMessages.num_unidades"
          ></Select>
        </div>
      </div>
      <h3 class="content-title">Região de atuação</h3>
      <RegionComponent
        ref="regionRef"
        :valuesRegions="
          dataRegister.incorporadora.perfil_empreendimentos.regiao_atuacao
        "
      />
    </div>
  </div>
</template>

<script>
import CheckboxGroup from "@/components/ui/CheckboxGroup.vue";
import Select from "@/components/ui/Select.vue";
import RegionComponent from "@/components/signup/RegionComponent.vue";

export default {
  name: "SecondStepBase",
  components: {
    CheckboxGroup,
    Select,
    RegionComponent,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      dataRegister: this.data,
      tipo_empreendimento: [
        { value: "vertical", label: "Residencial Vertical" },
        { value: "horizontal", label: "Residencial Horizontal" },
      ],
      segmento_atuacao: [
        { value: "special", label: "Especial (1 Dorm. até 40m²)" },
        { value: "economy", label: "Econômico (até R$ 264mil)" },
        { value: "standard", label: "Standard (R$ 264mil - R$ 500mil)" },
        { value: "medium", label: "Médio (R$ 500mil - R$ 1M)" },
        { value: "high", label: "Alto (R$ 1M - R$ 1.5M)" },
        { value: "lux", label: "Luxo (R$ 1.5M - R$ 3M)" },
        { value: "highlux", label: "Super Luxo (R$ 3M+)" },
      ],
      porte_obraOptions: [
        { value: "small", label: "Pequeno" },
        { value: "medium", label: "Médio" },
        { value: "large", label: "Grande" },
      ],
      num_unidadesOptions: [
        { value: "0-50", label: "0 – 50" },
        { value: "51-100", label: "51 – 100" },
        { value: "101-150", label: "101 – 150" },
        { value: "151-200", label: "151 – 200" },
        { value: "+de200", label: "+ de 200" },
      ],
      isRequiredMessages: {
        tipo_empreendimento: "",
        segmento_atuacao: "",
        tamanho: "",
        num_unidades: "",
      },
    };
  },
  mounted() {
    console.log("this.dataRegister secondStep", this.dataRegister);
  },
  methods: {
    updatePorteObraOption(value) {
      this.dataRegister.fundo_investimento
        ? (this.dataRegister.fundo_investimento.perfil_empreendimentos.porte_obra.tamanho[0] =
            value)
        : (this.dataRegister.incorporadora.perfil_empreendimentos.porte_obra.tamanho[0] =
            value);
      // this.dataRegister.fundo_investimento
      //   ? this.dataRegister.fundo_investimento.perfil_empreendimentos.porte_obra.tamanho.push(
      //       value
      //     )
      //   : this.dataRegister.incorporadora.perfil_empreendimentos.porte_obra.tamanho.push(
      //       value
      //     );
    },
    updateNumUnidadesOption(value) {
      this.dataRegister.fundo_investimento
        ? (this.dataRegister.fundo_investimento.perfil_empreendimentos.porte_obra.num_unidades[0] =
            value)
        : (this.dataRegister.incorporadora.perfil_empreendimentos.porte_obra.num_unidades[0] =
            value);
      // this.dataRegister.fundo_investimento
      //   ? this.dataRegister.fundo_investimento.perfil_empreendimentos.porte_obra.num_unidades.push(
      //       value
      //     )
      //   : this.dataRegister.incorporadora.perfil_empreendimentos.porte_obra.num_unidades.push(
      //       value
      //     );
    },
    handleValidateSecondStepForm() {
      const validatorType = this.dataRegister.fundo_investimento
        ? this.dataRegister.fundo_investimento.perfil_empreendimentos
        : this.dataRegister.incorporadora.perfil_empreendimentos;

      if (!validatorType.porte_obra.tamanho[0]) {
        this.isRequiredMessages.tamanho = "Campo obrigatório";
      } else {
        this.isRequiredMessages.tamanho = "";
      }

      if (!validatorType.porte_obra.num_unidades[0]) {
        this.isRequiredMessages.num_unidades = "Campo obrigatório";
      } else {
        this.isRequiredMessages.num_unidades = "";
      }

      if (
        !validatorType.tipo_empreendimento?.some((element) => element !== "")
      ) {
        this.isRequiredMessages.tipo_empreendimento = "Campo obrigatório";
      } else {
        this.isRequiredMessages.tipo_empreendimento = "";
      }

      if (!validatorType.segmento_atuacao?.some((element) => element !== "")) {
        this.isRequiredMessages.segmento_atuacao = "Campo obrigatório";
      } else {
        this.isRequiredMessages.segmento_atuacao = "";
      }

      const validateRegions = this.$refs.regionRef.handleValidateRegion();

      return (
        validateRegions &&
        Object.values(this.isRequiredMessages).every((value) => value === "")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.container-items-areaa {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.content-title {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $purple;
  margin-top: 12px;
}
</style>
