<template>
  <form class="form-area" @submit.prevent="handleSubmit">
    <h2 class="subtitle">PERFIL DE EMPREENDIMENTOS QUE INVESTEM</h2>
    <SecondStepBase :data="dataRegister" ref="secondStepBaseRef" />
    <div class="row gap-3 buttons-area">
      <div class="col-6 d-flex justify-content-start">
        <b-button
          class="button-width"
          variant="outline-dark"
          type="button"
          @click="onClickBack"
        >
          Etapa Anterior
        </b-button>
      </div>
      <div class="col-6 d-flex justify-content-end" :key="isKey">
        <b-button
          v-show="!loadingRegister"
          class="button-width"
          variant="primary"
          type="submit"
        >
          Finalizar Cadastro
        </b-button>
        <b-button
          v-show="loadingRegister"
          class="button-width"
          variant="primary"
          type="submit"
          disabled
        >
          <b-spinner small type="grow"></b-spinner>
          Loading...
        </b-button>
      </div>
    </div>
  </form>
</template>

<script>
import Button from "@/components/ui/Button.vue";
import SecondStepBase from "@/components/signup/SecondStepBase.vue";

export default {
  name: "FoundsStepTwo",
  components: {
    Button,
    SecondStepBase,
  },
  props: {
    data: Object,
    handleSubmitForm: Function,
    loadingRegister: Boolean,
  },
  data() {
    return {
      dataRegister: this.data,
      isKey: 0,
    };
  },
  methods: {
    onClickBack() {
      this.$emit("back-step");
    },
    async handleSubmit() {
      const validationFirstStep =
        this.$refs.secondStepBaseRef.handleValidateSecondStepForm();

      if (validationFirstStep) {
        this.loadingRegister = true;
        await this.handleSubmitForm(this.dataRegister);
        this.loadingRegister = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-area {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.buttons-area {
  margin-top: 40px;
}

.button-width {
  width: 200px;
}

.subtitle {
  width: 430px;
  margin: 56px 0 24px 0;

  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;
  text-transform: uppercase;
}
</style>
