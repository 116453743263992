<template>
  <div>
    <Founds
      v-if="userData.visao_usuario === 'fundo_investimento'"
      :userData="userDataFundoInvestimento"
    />
    <Merging
      v-else-if="userData.visao_usuario === 'incorporadora'"
      :userData="userDataIncorporadora"
    />
  </div>
</template>

<script>
import Merging from "@/views/auth/signup/Merging/index.vue";
import Founds from "@/views/auth/signup/Founds/index.vue";

export default {
  name: "Signup",
  components: {
    Merging,
    Founds,
  },
  data() {
    return {
      userDataFundoInvestimento: {
        uid: "",
        dados_gerais: {
          logo: "",
          cnpj: "",
          razao_social: "",
          email: "",
          telefone: "",
          site: "",
          data_fundacao: "",
          linkedin: "",
          endereco: "",
        },
        fundo_investimento: {
          socios: [],
          descricao: "",
          patrimonio_liquido_reais: "",
          num_investimentos_realizados: "",
          perfil_empreendimentos: {
            tipo_empreendimento: [],
            segmento_atuacao: [],
            porte_obra: {
              tamanho: [],
              num_unidades: [],
            },
            regiao_atuacao: [
              {
                regiao: "",
                estado: "",
                cidades: [
                  {
                    nome: "",
                    bairros: [],
                  },
                ],
              },
            ],
          },
          painel_empreendimentos: {
            salvos: [],
            interesses: [],
            aceitos: [],
            recusados: [],
            visualizados: []
          },
        },
      },
      userDataIncorporadora: {
        uid: "",
        dados_gerais: {
          logo: "",
          cnpj: "",
          razao_social: "",
          email: "",
          telefone: "",
          site: "",
          data_fundacao: "",
          linkedin: "",
          endereco: "",
        },
        incorporadora: {
          faturamento_ultimos_12_meses: "",
          vgv_lancado: "",
          numero_unidades_entregues: "",
          perfil_empreendimentos: {
            tipo_empreendimento: [],
            segmento_atuacao: [],
            porte_obra: {
              tamanho: [],
              num_unidades: [],
            },
            regiao_atuacao: [
              {
                regiao: "",
                estado: "",
                cidades: [
                  {
                    nome: "",
                    bairros: [],
                  },
                ],
              },
            ],
          },
        },
      },
    };
  },
  created() {

    this.userData = this.$route.params.updatedUserData || this.$session.get("client");

    if (this.userData.visao_usuario === 'fundo_investimento') {

      this.userDataFundoInvestimento.uid = this.userData.uid;
      this.userDataFundoInvestimento.dados_gerais.cnpj = this.userData.cnpj;
      this.userDataFundoInvestimento.dados_gerais.razao_social = this.userData.razao_social;
    }

    if (this.userData.visao_usuario === 'incorporadora') {
      
      this.userDataIncorporadora.uid = this.userData.uid;
      this.userDataIncorporadora.dados_gerais.cnpj = this.userData.cnpj;
      this.userDataIncorporadora.dados_gerais.razao_social = this.userData.razao_social;
    }

  },
};
</script>
