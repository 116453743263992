<template>
  <div class="textArea-area">
    <label :for="id" class="textArea-label">{{ label }}</label>
    <textarea
      :class="getClassObject"
      :id="id"
      :name="name"
      :placeholder="placeholder"
      v-model="textAreaValue"
    ></textarea>
    <span v-if="requiredMessage" class="error-message">{{
      requiredMessage
    }}</span>
  </div>
</template>

<script>
export default {
  name: "TextArea",
  props: {
    value: String,
    id: String,
    name: String,
    placeholder: String,
    label: String,
    requiredMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      textAreaValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.textAreaValue = newValue;
    },
    textAreaValue(newVal) {
      this.$emit("input", newVal);
    },
  },
  computed: {
    getClassObject() {
      return {
        error: this.requiredMessage,
        "textArea-content": true,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.textArea-area {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.textArea-label {
  font-size: 14px;
  font-weight: 600;
  color: $neutral-95;
}

.textArea-content {
  width: 100%;
  padding: 9px 16px;
  border: 1px solid $neutral-50;
  border-radius: 6px;
  color: $neutral-95;
  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
  transition: all 0.3s ease;
  outline: none;
  &.error {
    border-color: $red;
  }
}

.error-message {
  position: absolute;
  bottom: -16px;
  font-size: 11px;
  color: $red;
}
</style>
