class Validators {
  constructor() {}

  validateEmail(email) {
    const validateRegex = /\S+@\S+\.\S+/;
    return validateRegex.test(email);
  }

  validateCnpj(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj.length !== 14 || /^(.)\1+$/.test(cnpj)) return false;

    const calcDigit = (str, weights) => {
      let sum = 0;
      for (let i = 0; i < str.length; i++) {
        sum += parseInt(str[i]) * weights[i];
      }
      const remainder = sum % 11;
      return remainder < 2 ? 0 : 11 - remainder;
    };

    const weights1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const weights2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

    const digit1 = calcDigit(cnpj.substring(0, 12), weights1);
    const digit2 = calcDigit(cnpj.substring(0, 13), weights2);

    if (digit1 !== parseInt(cnpj[12]) || digit2 !== parseInt(cnpj[13]))
      return false;

    return true;
  }
}

export default new Validators();
