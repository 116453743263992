<template>
  <form class="form-area" @submit.prevent="handleSubmit">
    <FirstStepBase
      :data="dataRegister"
      ref="firstStepBaseRef"
      :type="'fundo_investimento'"
    />
    <div class="row gap-3">
      <div class="col-6">
        <TextArea
          id="partners"
          name="partners"
          label="Sócios"
          v-model="dataRegister.fundo_investimento.socios[0]"
          placeholder="Digite os sócios da empresa"
          :requiredMessage="isRequiredMessages.socios"
        />
      </div>
      <div class="col-6">
        <TextArea
          id="description"
          name="description"
          label="Descrição do fundo"
          v-model="dataRegister.fundo_investimento.descricao"
          placeholder="Digite a descrição da empresa"
          :requiredMessage="isRequiredMessages.descricao"
        />
      </div>
    </div>
    <div class="row gap-3">
      <div class="col-3">

        <Input
          id="patrimony"
          name="patrimony"
          label="Patrimônio líquido"
          v-model="dataRegister.fundo_investimento.patrimonio_liquido_reais"
          placeholder="R$ 0,00"
          @input="updatePatrimonyOption"
          :requiredMessage="isRequiredMessages.patrimonio_liquido_reais"
          v-numeric-only
        />

        <!-- <Select
          :id="'patrimony'"
          :name="'patrimony'"
          :label="'Patrimônio líquido'"
          :options="patrimonio_liquido_reais"
          :value="dataRegister.fundo_investimento.patrimonio_liquido_reais"
          @input="updatePatrimonyOption"
          :requiredMessage="isRequiredMessages.patrimonio_liquido_reais"
        /> -->

      </div>
      <div class="col-3">

        <Input
          id="numberInvestments"
          name="numberInvestments"
          label="Nº de investimentos realizados"
          v-model="dataRegister.fundo_investimento.num_investimentos_realizados"
          placeholder="0"
          @input="updateNumberInvestmentsOption"
          :requiredMessage="isRequiredMessages.num_investimentos_realizados"
        />

        <!-- <Select
          :id="'numberInvestments'"
          :name="'numberInvestments'"
          :label="'Nº de investimentos realizados'"
          :options="num_investimentos_realizados"
          :value="
            dataRegister.fundo_investimento.num_investimentos_realizados
          "
          @input="updateNumberInvestmentsOption"
          :requiredMessage="isRequiredMessages.num_investimentos_realizados"
        /> -->

      </div>
    </div>
    <div class="row gap-3 buttons-area">
      <div class="col-6 d-flex justify-content-start">
        <b-button
          class="button-width"
          variant="outline-dark"
          type="button"
          @click="onClickCancel"
        >
          Cancelar
        </b-button>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <b-button class="button-width" variant="primary" type="submit">
          Próxima etapa
        </b-button>
      </div>
    </div>
  </form>
</template>

<script>
import Input from "@/components/ui/Input.vue";
import TextArea from "@/components/ui/TextArea.vue";
import Select from "@/components/ui/Select.vue";
import Button from "@/components/ui/Button.vue";
import ImportImage from "@/components/ui/ImportImage.vue";

import FirstStepBase from "@/components/signup/FirstStepBase.vue";

import Formatters from "@/utils/Formatters.js";

export default {
  name: "FoundsStepOne",
  components: {
    Input,
    TextArea,
    Select,
    Button,
    ImportImage,
    FirstStepBase,
  },
  props: {
    data: Object,
    handleSubmitForm: Function,
  },
  data() {
    return {
      dataRegister: this.data,
      patrimonio_liquido_reais: "",
      num_investimentos_realizados: "",
      isRequiredMessages: {
        socios: "",
        descricao: "",
        patrimonio_liquido_reais: "",
        num_investimentos_realizados: "",
      },
    };
  },
  mounted() {
    console.log(this.dataRegister, "Fundo de Investimento - Passo 1");
    this.updatePatrimonyOption(String(this.dataRegister.fundo_investimento.patrimonio_liquido_reais).replace(".", ""));
  },
  directives: {
    'numeric-only': {
      bind(el) {
        el.addEventListener('keydown', (e) => {
          if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
            e.preventDefault();
          }
        });
      }
    }
  },
  methods: {
    updatePatrimonyOption(value) {
      //TODO - Passar para push em uma v2
      const valueWithOutString = value.replace(/\D/g, "").replace("R$ ", "")
      this.dataRegister.fundo_investimento.patrimonio_liquido_reais = Formatters.formatMoney(valueWithOutString)
    },
    updateNumberInvestmentsOption(value) {
      //TODO - Passar para push em uma v2
      this.dataRegister.fundo_investimento.num_investimentos_realizados =
        value;
    },
    onClickCancel() {
      this.$router.push({
        name: "Login",
      });
    },
    handleChangePhoto(selectedFile) {
      this.dataRegister.dados_gerais.photo = selectedFile;
    },
    onValidateForm() {
      if (this.dataRegister.fundo_investimento.socios[0] === "") {
        this.isRequiredMessages.socios = "Campo obrigatório";
      } else {
        this.isRequiredMessages.socios = "";
      }

      if (this.dataRegister.fundo_investimento.descricao === "") {
        this.isRequiredMessages.descricao = "Campo obrigatório";
      } else {
        this.isRequiredMessages.descricao = "";
      }

      if (
        this.dataRegister.fundo_investimento.patrimonio_liquido_reais === ""
      ) {
        this.isRequiredMessages.patrimonio_liquido_reais = "Campo obrigatório";
      } else {
        this.isRequiredMessages.patrimonio_liquido_reais = "";
      }

      if (
        this.dataRegister.fundo_investimento.num_investimentos_realizados ===
        ""
      ) {
        this.isRequiredMessages.num_investimentos_realizados =
          "Campo obrigatório";
      } else {
        this.isRequiredMessages.num_investimentos_realizados = "";
      }

      return Object.values(this.isRequiredMessages).every(
        (value) => value === ""
      );
    },
    handleSubmit() {
      const validationFirstStep =
        this.$refs.firstStepBaseRef.handleValidateFirstStepForm();

      if (this.onValidateForm() && validationFirstStep) {
        this.dataRegister.fundo_investimento.patrimonio_liquido_reais = parseFloat(this.dataRegister.fundo_investimento.patrimonio_liquido_reais.replace("R$ ", "").replace(/\./g, "").replace(",", "."))
        this.dataRegister.dados_gerais.telefone = this.dataRegister.dados_gerais.telefone.replace(/\D/g, "")
        this.handleSubmitForm(this.dataRegister.fundo_investimento);
      }
      //this.handleSubmitForm(this.dataRegister);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-area {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.buttons-area {
  margin-top: 40px;
}

.button-width {
  width: 200px;
}
</style>
