<template>
  <div class="container-fluid m-0 p-0">
    <div 
      class="row gap-2 mb-4" 
      v-for="(regionInfo, index) in values"
      :key="index"
      v-bind:data-index="index"
      :id="'region-info' + index"
    >

      <div class="col-3" :key="index">
        <Select 
          :id="'cityregion' + index"
          :index="index"
          :name="'cityregion' + index"
          :label="'Região'"
          :options="regioes"
          :value="locations[index].regiao"
          :requiredMessage="requiredMessage[index].regiao"
          @input="handleRegiaoChange"
        />
        <div style="padding-top: 7px;">
          <button v-if="index > 0"
            :id="'delete' + index" 
            class="add-item" @click="handleDelRegion($event, index)">
            <trash-icon size="1x" class="custom-class" ></trash-icon>
          </button>
        </div>
      </div>

      <div class="col-2">
        <Select
          :id="'state' + index"
          :index="index"
          :name="'state' + index"
          :label="'Estado'"
          :options="estadosPorRegiao[locations[index].regiao] || []"
          :value="locations[index].estado"
          :requiredMessage="requiredMessage[index].estado"
          :key="locations[index].regiao"
          @input="handleEstadoChange"
          :disabled="locations[index].isEstadoDisabled" 
        />
      </div>

      <div class="col-7">
        <Input
          :id="'autocompleteCidade' + index"
          :index="index"
          :name="'cidade' + index"
          :label="'Cidade'"
          :value="locations[index].cidade"
          :requiredMessage="requiredMessage[index].cidade"
          @input="handleCidadeChange"
          :disabled="locations[index].isCidadeDisabled" 
        />

        <strong class="pl-1 text-bold d-block text-danger" v-if="locations[index].alertaCidade">
          Selecione uma cidade correspondente ao estado selecionado</strong>
      </div>
      
    </div>

    <button class="add-item" @click="handleAddRegion">
      + Adicionar mais cidades
    </button>

  </div>
</template>
<script>
import Select from "@/components/ui/Select.vue";
import Input from "@/components/ui/Input.vue";

import {
  TrashIcon
} from "vue-feather-icons";

export default {
  name: "RegionComponent",
  components: { Select, Input, TrashIcon },
  props: {
    valuesRegions: Array,
  },
  data() {
    return {
      values: this.valuesRegions,
      regioes: [
        { label: "Norte", value: "norte" },
        { label: "Nordeste", value: "nordeste" },
        { label: "Centro-Oeste", value: "centro-oeste" },
        { label: "Sudeste", value: "sudeste" },
        { label: "Sul", value: "sul" },
      ],
      estadosPorRegiao: {
        "norte": [
          { label: "Acre", value: "AC" },
          { label: "Amapá", value: "AP" },
          { label: "Amazonas", value: "AM" },
          { label: "Pará", value: "PA" },
          { label: "Rondônia", value: "RO" },
          { label: "Roraima", value: "RR" },
          { label: "Tocantins", value: "TO" },
        ],
        "nordeste": [
          { label: "Alagoas", value: "AL" },
          { label: "Bahia", value: "BA" },
          { label: "Ceará", value: "CE" },
          { label: "Maranhão", value: "MA" },
          { label: "Paraíba", value: "PB" },
          { label: "Pernambuco", value: "PE" },
          { label: "Piauí", value: "PI" },
          { label: "Rio Grande do Norte", value: "RN" },
          { label: "Sergipe", value: "SE" },
        ],
        "centro-oeste": [
          { label: "Distrito Federal", value: "DF" },
          { label: "Goiás", value: "GO" },
          { label: "Mato Grosso", value: "MT" },
          { label: "Mato Grosso do Sul", value: "MS" },
        ],
        "sudeste": [
          { label: "Espírito Santo", value: "ES" },
          { label: "Minas Gerais", value: "MG" },
          { label: "Rio de Janeiro", value: "RJ" },
          { label: "São Paulo", value: "SP" },
        ],
        "sul": [
          { label: "Paraná", value: "PR" },
          { label: "Rio Grande do Sul", value: "RS" },
          { label: "Santa Catarina", value: "SC" },
        ],
      },      
      locations: [
        {
          regiao: "",
          estado: "",
          cidade: "",
          bairro: "",
          grupoBairros: [],
          isEstadoDisabled: true,
          isCidadeDisabled: true,
          alertaCidade: false,
          place: {},
          autocomplete: {}
        }
      ],
      requiredMessage: [
        {
          regiao: "",
          estado: "",
          cidade: "",
          bairro: ""
        },
      ],
    };
  },
  computed: {},
  async mounted() {

    const index = document.getElementById("region-info0").dataset.index;

    await this.loadGoogleMaps().then(async () => {
      await this.initAutocompleteCidade(index);
    });
  },
  methods: {
    async handleRegiaoChange(newValue, _index) {
      this.locations[_index].regiao = newValue;
      this.locations[_index].isEstadoDisabled = false;
    },
    async handleEstadoChange(newValue, _index) {
      this.locations[_index].estado = newValue;
      this.locations[_index].isCidadeDisabled = false;
    },
    async handleCidadeChange(newValue, _index) {
      this.locations[_index].cidade = newValue;
    },
    loadGoogleMaps() {
      return new Promise((resolve) => {
        if (typeof google === "undefined") {
          var script = document.createElement("script");
          script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_API_KEY}&libraries=places`;
          script.onload = resolve;
          document.head.appendChild(script);
        } else {
          resolve();
        }
      });
    },
    async initAutocompleteCidade(_index) {

      const input = document.getElementById("autocompleteCidade" + _index);

      const options = {
        componentRestrictions: {
          country: "BR",
        },
        types: ["(cities)"],
        // types: ["geocode"],
        input: "SP",
        fields: ["geometry", "formatted_address", "address_components"],
      };

      this.locations[_index].autocomplete = await new google.maps.places.Autocomplete(
        input,
        options
      );

      await this.locations[_index].autocomplete.addListener("place_changed", () => {

        this.locations[_index].place = this.locations[_index].autocomplete.getPlace();

        const locality = this.locations[_index].place.address_components.find((component) => {
          return component.types.includes("locality");
        });
        const state = this.locations[_index].place.address_components.find((component) => {
          return component.types.includes("administrative_area_level_1");
        });

        if (state.short_name === this.locations[_index].estado) {
          this.locations[_index].cidade = locality.long_name;
          this.locations[_index].alertaCidade = false;
        } else {
          this.locations[_index].cidade = "";
          this.locations[_index].alertaCidade = true;
        }

        this.values[_index] = {
          regiao: this.locations[_index].regiao,
          estado: this.locations[_index].estado,
          cidades: [
            {
              nome: this.locations[_index].cidade,
              bairros: this.locations[_index].grupoBairros,
            },
          ],
        };

      });
    },
    async handleAddRegion(e) {

      e.preventDefault();

      this.locations.push({
        regiao: "",
        estado: "",
        cidade: "",
        bairro: "",
        grupoBairros: [],
        alertaCidade: false,
        isEstadoDisabled: true,
        isCidadeDisabled: true,
        place: {},
        autocomplete: {}
      })

      this.values.push({
        regiao: "",
        estado: "",
        cidades: [
          {
            nome: "",
            bairros: [""],
          },
        ],
      });

      this.requiredMessage.push({
        regiao: "",
        estado: "",
        cidade: ""
      });

      await this.loadGoogleMaps().then(async () => {
        await this.initAutocompleteCidade(this.locations.length - 1);
      });       
    },
    async handleDelRegion(e, index) {
      e.preventDefault();

      this.locations.splice(index, 1);
      this.values.splice(index, 1);
      this.requiredMessage.splice(index, 1);
    },
    handleValidateRegion() {

      this.values.forEach((value, index) => {

        if (value.regiao === "") {
          this.requiredMessage[index].regiao = "Campo obrigatório";
        } else {
          this.requiredMessage[index].regiao = "";
        }

        if (value.estado === "") {
          this.requiredMessage[index].estado = "Campo obrigatório";
        } else {
          this.requiredMessage[index].estado = "";
        }

        if (value.cidades[0].nome === "") {
          this.requiredMessage[index].cidade = "Campo obrigatório";
          this.locations[index].alertaCidade = false;
        } else {
          this.requiredMessage[index].cidade = "";
        }
      });

      return (
        this.requiredMessage.findIndex((value) =>
          Object.values(value).includes("Campo obrigatório")
        ) === -1
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.add-item {
  background: none;
  border: none;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $primary;

  cursor: pointer;

  transition: all 0.3s ease-in-out;

  &:hover {
    filter: brightness(0.8);
  }
}
</style>
