<template>
  <form class="form-area" @submit.prevent="handleSubmit">
    <FirstStepBase :data="dataRegister" ref="firstStepBaseRef" :type="'incorporadora'" />
    <div class="row gap-3">

      <div class="col-3">

        <Input
          id="invoicing"  
          name="invoicing"
          label="Faturamento (Últimos 12 Meses)"
          v-model="dataRegister.incorporadora.faturamento_ultimos_12_meses"
          placeholder="R$ 0,00"
          @input="updateInvoicingOption"
          :requiredMessage="isRequiredMessages.faturamento_ultimos_12_meses"
        />

        <!-- <b-form-group
          label="Faturamento (Últimos 12 Meses)"
          label-for="avancoObra"
          label-class="font-weight-bold"
        >
          <b-form-input
            id="invoicing"
            v-model="dataRegister.incorporadora.faturamento_ultimos_12_meses"
            type="text"
            :requiredMessage="isRequiredMessages.faturamento_ultimos_12_meses"
          />
        </b-form-group> -->

        <!-- <Select :id="'invoicing'" :name="'invoicing'" :label="'Faturamento (últimos 12 meses)'" :options="invoicing"
          :value="dataRegister.incorporadora.faturamento_ultimos_12_meses[0]" @input="updateInvoicingOption"
          :requiredMessage="isRequiredMessages.faturamento_ultimos_12_meses" /> -->
      
        </div>
      <div class="col-3">

        <Input
          id="vgvReleases"
          name="vgvReleases"
          label="VGV Lançado"
          v-model="dataRegister.incorporadora.vgv_lancado"
          placeholder="R$ 0,00"
          @input="updateVgvReleasesOption"
          :requiredMessage="isRequiredMessages.vgv_lancado"
          v-numeric-only
        />

        <!-- <b-form-group
          label="VGV Lançado"
          label-for="vgvReleases"
          label-class="font-weight-bold"
        >
          <b-form-input
            id="vgvReleases"
            v-model="dataRegister.incorporadora.vgv_lancado"
            type="text"
            :requiredMessage="isRequiredMessages.vgv_lancado"
          />
        </b-form-group> -->

        <!-- <Select :id="'vgvReleases'" :name="'vgvReleases'" :label="'VGV lançado'" :options="vgvReleases"
          :value="dataRegister.incorporadora.vgv_lancado[0]" @input="updateVgvReleasesOption"
          :requiredMessage="isRequiredMessages.vgv_lancado" /> -->

      </div>
      <div class="col-3">

        <Input
          id="deliveryUnits"
          name="deliveryUnits"
          label="Nº de Unidades Entregues"
          v-model="dataRegister.incorporadora.numero_unidades_entregues"
          placeholder="0"
          @input="updateDeliveryUnitsOption"
          :requiredMessage="isRequiredMessages.numero_unidades_entregues"
          v-numeric-only
        />

        <!-- <b-form-group
          label="Nº de Unidades Entregues"
          label-for="deliveryUnits"
          label-class="font-weight-bold"
        >
          <b-form-input
            id="deliveryUnits"
            v-model="dataRegister.incorporadora.numero_unidades_entregues"
            type="text"
            :requiredMessage="isRequiredMessages.numero_unidades_entregues"
          />
        </b-form-group> -->

        <!-- <Select :id="'deliveryUnits'" :name="'deliveryUnits'" :label="'Nº de unidades entregas'"
          :options="deliveryUnits" :value="dataRegister.incorporadora.numero_unidades_entregues[0]"
          @input="updateDeliveryUnitsOption" :requiredMessage="isRequiredMessages.numero_unidades_entregues" /> -->

      </div>
    </div>
    <div class="row gap-3 buttons-area">
      <div class="col-6 d-flex justify-content-start">
        <b-button class="button-width" variant="outline-dark" type="button" @click="onClickCancel">
          Cancelar
        </b-button>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <b-button class="button-width" variant="primary" type="submit">
          Próxima etapa
        </b-button>
      </div>
    </div>
  </form>
</template>

<script>
import Input from "@/components/ui/Input.vue";
import TextArea from "@/components/ui/TextArea.vue";
import Select from "@/components/ui/Select.vue";
import Button from "@/components/ui/Button.vue";
import ImportImage from "@/components/ui/ImportImage.vue";

import FirstStepBase from "@/components/signup/FirstStepBase.vue";

import Formatters from "@/utils/Formatters.js";

export default {
  name: "MergingStepOne",
  components: {
    Input,
    TextArea,
    Select,
    Button,
    ImportImage,
    FirstStepBase,
  },
  props: {
    data: Object,
    handleSubmitForm: Function,
  },
  data() {
    return {
      dataRegister: this.data,
      invoicing: "",
      vgvReleases: "",
      deliveryUnits: "",
      isRequiredMessages: {
        faturamento_ultimos_12_meses: "",
        vgv_lancado: "",
        numero_unidades_entregues: "",
      },
    };
  },
  mounted() {
    console.log(this.dataRegister, "Incorporadora - Passo 1");
    this.updateInvoicingOption(String(this.dataRegister.incorporadora.faturamento_ultimos_12_meses).replace(".", ""));
    this.updateVgvReleasesOption(String(this.dataRegister.incorporadora.vgv_lancado).replace(".", ""));
  },
  directives: {
    'numeric-only': {
      bind(el) {
        el.addEventListener('keydown', (e) => {
          if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
            e.preventDefault();
          }
        });
      }
    }
  },
  methods: {
    updateInvoicingOption(value) {
      const valueWithOutString = value.replace(/\D/g, "").replace("R$ ", "")
      this.dataRegister.incorporadora.faturamento_ultimos_12_meses = Formatters.formatMoney(valueWithOutString)
      //this.dataRegister.incorporadora.faturamento_ultimos_12_meses.push(value);
    },
    updateVgvReleasesOption(value) {
      const valueWithOutString = value.replace(/\D/g, "").replace("R$ ", "")
      this.dataRegister.incorporadora.vgv_lancado = Formatters.formatMoney(valueWithOutString)
      //this.dataRegister.incorporadora.vgv_lancado.push(value);
    },
    updateDeliveryUnitsOption(value) {
      this.dataRegister.incorporadora.numero_unidades_entregues = value;
      //this.dataRegister.incorporadora.numero_unidades_entregues.push(value);
    },
    onClickCancel() {
      this.$router.push({
        name: "Login",
        path: "/login",
      });
    },
    // onlyNumbers(event) {
    //   const keyCode = event.keyCode || event.which;
    //   if (
    //     (keyCode < 48 || keyCode > 57) &&
    //     keyCode !== 8 &&
    //     keyCode !== 9 &&
    //     (keyCode < 37 || keyCode > 40)
    //   ) {
    //     event.preventDefault();
    //   }
    // },    
    handleChangePhoto(selectedFile) {
      this.dataRegister.photo = selectedFile;
    },
    onValidateForm() {
      Object.keys(this.isRequiredMessages).forEach((key) => {
        if (
          !this.dataRegister.incorporadora[key] ||
          !this.dataRegister.incorporadora[key][0]
        ) {
          this.isRequiredMessages[key] = "Campo obrigatório";
        } else {
          this.isRequiredMessages[key] = "";
        }
      });

      return Object.values(this.isRequiredMessages).every(
        (value) => value === ""
      );
    },
    handleSubmit() {
      const validationFirstStep =
        this.$refs.firstStepBaseRef.handleValidateFirstStepForm();

      if (this.onValidateForm() && validationFirstStep) {
        this.dataRegister.incorporadora.faturamento_ultimos_12_meses = parseFloat(this.dataRegister.incorporadora.faturamento_ultimos_12_meses.replace("R$ ", "").replace(/\./g, "").replace(",", "."))
        this.dataRegister.incorporadora.vgv_lancado = parseFloat(this.dataRegister.incorporadora.vgv_lancado.replace("R$ ", "").replace(/\./g, "").replace(",", "."))
        this.dataRegister.dados_gerais.telefone = this.dataRegister.dados_gerais.telefone.replace(/\D/g, "")

        this.handleSubmitForm(this.dataRegister.incorporadora);
      }

      console.log(this.dataRegister.incorporadora, "stepone handle");
      //this.handleSubmitForm(this.dataRegister.incorporadora);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-area {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.buttons-area {
  margin-top: 40px;
}

.button-width {
  width: 200px;
}
</style>
