var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs flex-base-center"},_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index,class:{
      'flex-base-center tab-item': true,
      'cursor-pointer': !_vm.tabClicked,
    },on:{"click":function($event){_vm.tabClicked ? _vm.handleSelectedTab(tab.label) : null}}},[(!tab.concluid)?_c('div',{class:{
        'flex-base-center text-base tab-number': true,
        disabled: _vm.selected !== tab.label,
        'tab-concluid': tab.concluid,
      }},[_vm._v(" "+_vm._s(index + 1)+" ")]):_c('div',{class:{
        'flex-base-center text-base tab-number': true,
        disabled: _vm.selected !== tab.label,
        'tab-concluid': tab.concluid,
      }}),_c('p',{class:{
        'flex-base-center text-base tab-text': true,
        disabled: _vm.selected !== tab.label,
        'tab-concluid': tab.concluid,
      }},[_vm._v(" "+_vm._s(tab.name)+" ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }