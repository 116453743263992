<template>
  <div class="tabs flex-base-center">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      @click="tabClicked ? handleSelectedTab(tab.label) : null"
      :class="{
        'flex-base-center tab-item': true,
        'cursor-pointer': !tabClicked,
      }"
    >
      <div
        :class="{
          'flex-base-center text-base tab-number': true,
          disabled: selected !== tab.label,
          'tab-concluid': tab.concluid,
        }"
        v-if="!tab.concluid"
      >
        {{ index + 1 }}
      </div>
      <div
        :class="{
          'flex-base-center text-base tab-number': true,
          disabled: selected !== tab.label,
          'tab-concluid': tab.concluid,
        }"
        v-else
      ></div>
      <p
        :class="{
          'flex-base-center text-base tab-text': true,
          disabled: selected !== tab.label,
          'tab-concluid': tab.concluid,
        }"
      >
        {{ tab.name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    tabsRender: Array,
    tabSelected: String,
    clicked: Boolean,
  },
  data() {
    return {
      tabs: this.tabsRender,
      selected: this.tabSelected,
      tabClicked: this.clicked,
    };
  },
  watch: {
    tabSelected(newVal) {
      this.selected = newVal;
    },
  },
  methods: {
    handleSelectedTab(label) {
      this.selected = label;
      this.$emit("set-selected-tab", label);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-base-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tabs {
  flex-direction: row;

  gap: 94px;
}

.tab-item {
  padding: 10px 20px;
  border: none;

  .cursor-pointer {
    cursor: pointer;
  }
}

.text-base {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $purple;
}

.tab-number {
  position: relative;
  width: 40px;
  height: 40px;

  flex-grow: 0;

  padding: 6px 10px;
  border-radius: 34px;

  border: solid 2px $purple;
  background-color: $white;

  font-size: 20px;

  &.disabled {
    color: $neutral-50;
    border: solid 2px $neutral-50;
    cursor: default;

    opacity: 0.6;
  }

  &.tab-concluid {
    border: solid 1px $purple;

    &::after {
      content: "";
      position: absolute;

      width: 25px;
      height: 15px;

      background: url("../../assets/check-purple.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      top: 13px;
    }
  }
}

.tab-text {
  max-width: 140px;
  font-size: 16px;
  text-wrap: wrap;

  text-transform: uppercase;

  margin-top: 12px;

  &.disabled {
    color: $neutral-50;
    cursor: default;

    opacity: 0.6;
  }

  &.tab-concluid{
    color: $neutral-95;
  }
}
</style>
