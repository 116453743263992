<template>
  <div class="container-form-area">
    <div class="photo-logo-area">
      <ImportImage @handle-set-photo="handleChangePhoto" />
    </div>
    <div class="row gap-3">
      <div class="col-4">
        <Input
          id="cnpj"
          name="cnpj"
          label="CNPJ"
          v-model="dataRegister.dados_gerais.cnpj"
          placeholder="Digite o CNPJ"
          @input="formatedCnpj"
          :requiredMessage="isRequiredMessages.cnpj"
        />
      </div>
      <div class="col-8">
        <Input
          id="companyName"
          name="companyName"
          label="Razão Social"
          v-model="dataRegister.dados_gerais.razao_social"
          placeholder="Digite a razão social"
          :requiredMessage="isRequiredMessages.razao_social"
        />
      </div>
    </div>
    <div class="row gap-3">
      <div class="col-3">
        <Input
          id="email"
          name="email"
          label="E-mail"
          v-model="dataRegister.dados_gerais.email"
          placeholder="Digite o e-mail da empresa"
          :requiredMessage="isRequiredMessages.email"
        />
      </div>
      <div class="col-3">
        <Input
          id="phone"
          name="phone"
          label="Telefone"
          v-model="dataRegister.dados_gerais.telefone"
          placeholder="(00) 99999-9999"
          @input="updatePhoneValue"
          :requiredMessage="isRequiredMessages.telefone"
          v-numeric-only
        />
      </div>
      <div class="col-3">
        <Input
          id="site"
          name="site"
          label="Site"
          v-model="dataRegister.dados_gerais.site"
          placeholder="Digite o site da empresa"
        />
      </div>
      <div class="col-3">
        <Input
          id="foundationDate"
          name="foundationDate"
          label="Data de fundação da empresa"
          type="date"
          v-model="dataRegister.dados_gerais.data_fundacao"
          :placeholder="'DD/MM/YYYY'"
          :requiredMessage="isRequiredMessages.data_fundacao"
        />
      </div>
    </div>
    <div class="row gap-3">
      <div class="col-3">
        <Input
          id="linkedin"
          name="linkedin"
          label="Linkedin"
          v-model="dataRegister.dados_gerais.linkedin"
          placeholder="Digite o linkedin da empresa"
        />
      </div>
      <div class="col-9">
        <Input
          id="address"
          name="address"
          label="Endereço"
          v-model="dataRegister.dados_gerais.endereco"
          placeholder="Digite o endereço da empresa"
          :requiredMessage="isRequiredMessages.endereco"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/ui/Input.vue";
import TextArea from "@/components/ui/TextArea.vue";
import Select from "@/components/ui/Select.vue";
import Button from "@/components/ui/Button.vue";
import ImportImage from "@/components/ui/ImportImage.vue";

import Formatters from "@/utils/Formatters.js";
import Validators from "@/utils/Validators.js";

export default {
  name: "FirstStepBase",
  components: {
    Input,
    TextArea,
    Select,
    Button,
    ImportImage,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      dataRegister: this.data,
      isRequiredMessages: {
        cnpj: "",
        razao_social: "",
        email: "",
        telefone: "",
        data_fundacao: "",
        endereco: "",
      },
    };
  },
  created() {
    this.formatedCnpj();
  },
  mounted(){
    this.updatePhoneValue(this.dataRegister.dados_gerais.telefone)
    this.formatedCnpj(this.dataRegister.dados_gerais.cnpj)
  },
  methods: {
    updatePhoneValue(value) {
      const valueWithOutString = value.replaceAll(/\D/g, "")
      this.dataRegister.dados_gerais.telefone = Formatters.formatPhone(valueWithOutString);
    },
    formatedCnpj() {
      this.dataRegister.dados_gerais.cnpj = Formatters.formatCnpj(
        this.dataRegister.dados_gerais.cnpj
      );
    },
    onClickCancel() {
      this.$router.push({
        name: "Login",
      });
    },
    handleChangePhoto(selectedFile) {
      this.dataRegister.dados_gerais.photo = selectedFile;
    },
    handleValidateFirstStepForm() {
      Object.keys(this.isRequiredMessages).forEach((key) => {
        if (!this.dataRegister.dados_gerais[key]) {
          this.isRequiredMessages[key] = "Campo obrigatório";
        } else {
          this.isRequiredMessages[key] = "";
        }
      });

      if (!Validators.validateEmail(this.dataRegister.dados_gerais.email)) {
        this.isRequiredMessages.email = "E-mail inválido";
      }

      if (!Validators.validateCnpj(this.dataRegister.dados_gerais.cnpj)) {
        this.isRequiredMessages.cnpj = "CNPJ inválido";
      }

      return Object.values(this.isRequiredMessages).every(
        (value) => value === ""
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.container-form-area {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.photo-logo-area {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 32px 0;
}

.buttons-area {
  margin-top: 40px;
}

.button-width {
  width: 200px;
}
</style>
