<template>
  <label :for="idImage">
    <input :id="idImage" type="file" @change="handleChangePhoto" hidden />
    <img
      v-if="imageUrl"
      :src="imageUrl"
      alt="Selected Image"
      class="file-area"
    />
    <div v-else class="file-area empty-file-area">Logo</div>
  </label>
</template>

<script>
import axios from "axios";

export default {
  name: "ImportImage",
  props: {
    file: File,
    id: String,
  },
  data() {
    return {
      imageUrl: "",
      idImage: this.id,
    };
  },
  methods: {
    async handleChangePhoto(event) {
      try {
        const selectedFile = event.target.files[0];
        const formData = new FormData();
        formData.append("file", selectedFile);

        this.imageUrl = URL.createObjectURL(selectedFile);
        console.log("imageURL", this.imageUrl);
        this.$emit("handle-set-photo", formData);

        // const uninterceptedAxiosInstance = axios.create();
        // const response = await uninterceptedAxiosInstance.post(
        //   "/api/vitrine/uploadImage",
        //   formData,
        //   {
        //     headers: {
        //       "Content-Type": "multipart/form-data",
        //     },
        //   }
        // );

        // console.log("RESPONSE DATA", formData);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.file-area {
  width: 105px;
  height: 105px;

  border-radius: 4px;

  transition: all 0.4s ease-out;

  cursor: pointer;

  &:hover {
    filter: brightness(0.85);
  }
}

.empty-file-area {
  flex-grow: 0;
  border: solid 1px #868e96;
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
