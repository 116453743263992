<template>
  <div :class="{ classComponent: !classComponent, 'checkbox-area': true }">
    <p class="title">{{ titleComponent }}</p>
    <label
      class="d-flex flex-row gap-2 justify-content-start align-items-center"
      v-for="(option, index) in options"
      :key="index"
    >
      <input type="checkbox" :value="option.value" v-model="selectedOptions" />
      <span class="label-span">{{ option.label }}</span>
    </label>
    <span v-if="requiredMessage" class="error-message">{{
      requiredMessage
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
      class: String,
    },
    value: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    requiredMessage: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      selectedOptions: this.value,
      classComponent: this.class,
      titleComponent: this.title,
    };
  },
  watch: {
    selectedOptions: function (newVal) {
      this.$emit("input", newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-area {
  position: relative;
  height: fit-content;
}
.title {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;

  margin-bottom: 8px;
}
.label-span {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;

  margin-left: 4px;
}

.error-message {
  position: absolute;

  bottom: -16px;

  font-size: 11px;
  color: $red;
}
</style>
