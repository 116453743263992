var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-area"},[_c('label',{staticClass:"input-label",attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]),(_vm.type === 'date')?_c('b-form-datepicker',{class:{
      error: _vm.requiredMessage,
      'input-content': true,
      date: _vm.type === 'date',
    },attrs:{"id":_vm.id,"name":_vm.name,"locale":"pt-BR","hideHeader":"","placeholder":_vm.placeholder,"date-format-options":{
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },"right":"","icon":"calendar"},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}):((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],class:{
      error: _vm.requiredMessage,
      'input-content': true,
      date: _vm.type === 'date',
    },attrs:{"id":_vm.id,"name":_vm.name,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],class:{
      error: _vm.requiredMessage,
      'input-content': true,
      date: _vm.type === 'date',
    },attrs:{"id":_vm.id,"name":_vm.name,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputValue,null)},on:{"change":function($event){_vm.inputValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],class:{
      error: _vm.requiredMessage,
      'input-content': true,
      date: _vm.type === 'date',
    },attrs:{"id":_vm.id,"name":_vm.name,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":_vm.type},domProps:{"value":(_vm.inputValue)},on:{"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}}),(_vm.requiredMessage)?_c('span',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.requiredMessage))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }