<template>
  <b-modal
    name="modal"
    id="modal-success-signup"
    hide-footer
    hide-header
    centered
    size="md"
  >
    <div class="container-fluid p-0 m-0">
      <div class="container-fluid p-0 m-0">
        <div
          class="d-flex flex-column m-3 pb-3 align-items-center justify-content-center"
        >
          <img src="@/assets/success-modal-icon.svg" alt="Sucesso" />
        </div>
        <div
          class="d-flex flex-column px-3 my-1 align-items-center justify-content-center text-center"
        >
          <h3 class="title-success-error">
            Cadastro realizado com sucesso!
          </h3>
          <p class="message-success-error">
            Em breve, você receberá em seu e-mail <br />
            os dados de acesso.
          </p>
        </div>
        <div
          class="d-flex flex-column px-5 mt-2 mb-4 w-100 align-items-center justify-content-center"
        >
          <b-button class="mt-3 w-100" variant="primary" @click="closeModal">
            Continuar
          </b-button>
        </div>
      </div>
    </div></b-modal
  >
</template>

<script>
export default {
  nome: "modalSuccess",
  methods: {
    closeModal() {
      this.$router.push({
        name: "Login",
      });
      this.$bvModal.hide("modal-success-signup");
    },
  },
};
</script>

<style lang="scss" scoped>
.title-success-error {
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;
}

.message-success-error {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;
}
</style>
