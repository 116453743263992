<template>
  <div class="select-area">
    <label class="select-label" :for="id">{{ label }}</label>
    <select
      :class="getClassObject"
      :id="id"
      :name="name"
      v-model="selectedValue"
      :disabled="disabled"
    >
      <option value="">{{ optionNullValue }}</option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <span v-if="requiredMessage" class="error-message">{{
      requiredMessage
    }}</span>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    value: String,
    id: String,
    index: Number,
    name: String,
    label: String,
    options: {
      type: Array,
      required: true,
    },
    requiredMessage: {
      type: String,
      default: "",
      required: false,
    },
    value: {
      type: String || Number,
      required: true,
      default: null,
    },
    optionNullValue: {
      type: String,
      required: false,
      default: "Selecione uma opção",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: this.value,
    };
  },
  watch: {
    selectedValue(newSelectedValue) {
      this.$emit("input", newSelectedValue, this.index);
    }   
  },
  computed: {
    getClassObject() {
      return {
        error: this.requiredMessage,
        "select-content": true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.select-area {
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 4px;
}

.select-label {
  font-size: 14px;
  font-weight: 600;
  color: $neutral-95;
}

.select-content {
  width: 100%;
  padding: 9px 16px;

  border: 1px solid $neutral-50;
  border-radius: 6px;

  color: $neutral-95;

  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
  transition: all 0.3s ease;

  outline: none;

  cursor: pointer;

  &.error {
    border-color: $red;
  }

  option {
    color: $neutral-95;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../../assets/arrow-select.svg") white no-repeat 98.5% !important; /* !important used for overriding all other customisations */
  background: url("../../assets/arrow-select.svg") white no-repeat
    calc(100% - 10px) !important; /* Better placement regardless of input width */
}

.error-message {
  position: absolute;

  bottom: -16px;

  font-size: 11px;
  color: $red;
}
</style>
