<template>
  <div class="container-fluid">
    <h1 class="title">Cadastro Agente Financeiro</h1>
    <Tabs :tabSelected="tabSelected" :tabsRender="tabs" :clicked="false" />
    <StepOne
      v-if="tabSelected === 'StepOne'"
      :data="dataRegister"
      :handleSubmitForm="handleNextStep"
    />
    <StepTwo
      v-else-if="tabSelected === 'StepTwo'"
      :data="dataRegister"
      :handleSubmitForm="handleSubmitForm"
      @back-step="handleBackStep"
      :loadingRegister="loadingRegister"
    />
    <ModalSuccess />
  </div>
</template>

<script>
import Tabs from "@/components/ui/Tabs.vue";
import StepOne from "./StepOne.vue";
import StepTwo from "../commomSteps/StepTwo.vue";
import ModalSuccess from "@/views/auth/signup/ModalSuccess.vue";
import axios from "axios";

export default {
  name: "Founds",
  components: { StepOne, StepTwo, Tabs, ModalSuccess },
  props: {
    userData: Object,
  },
  data() {
    return {
      dataRegister: this.userData,
      tabs: [
        {
          name: "Informações Gerais",
          label: "StepOne",
          concluid: false,
        },
        {
          name: "Perfil de Empreendimento",
          label: "StepTwo",
          concluid: false,
        },
      ],
      tabSelected: "StepOne",
      loadingRegister: false,
    };
  },
  methods: {
    handleNextStep() {
      const findTabindex = this.tabs.findIndex(
        (tab) => tab.label === this.tabSelected
      );
      if (findTabindex < this.tabs.length - 1) {
        this.tabSelected = this.tabs[findTabindex + 1].label;
        this.tabs[findTabindex].concluid = true;
      }
    },
    handleBackStep() {
      const findTabindex = this.tabs.findIndex(
        (tab) => tab.label === this.tabSelected
      );
      if (findTabindex > 0) {
        this.tabSelected = this.tabs[findTabindex - 1].label;
        this.tabs[findTabindex - 1].concluid = false;
      }
    },
    async handleSubmitForm() {
      const uninterceptedAxiosInstance = axios.create();
      try {
        const logo = this.dataRegister.dados_gerais.photo;
        delete this.dataRegister.dados_gerais.photo;

        if (logo && logo.has("file")) {
          const response = await uninterceptedAxiosInstance.post(
            "/api/vitrine/uploadImage",
            logo,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          this.dataRegister.dados_gerais.logo = response.data.upload;
        } else {
          this.dataRegister.dados_gerais.logo = "";
        }

        await uninterceptedAxiosInstance
          .post("/api/vitrine/fundo_investimento  ", this.dataRegister)
          .then((response) => {
            this.$bvModal.show("modal-success-signup");
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loadingRegister = false;
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  background-color: $background;
  padding: 5% 14%;
  height: 100%;
}

.title {
  margin-bottom: 40px;

  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: $neutral-95;
}
</style>
