<template>
  <button
    :type="type"
    class="button"
    :disabled="disabled"
    :class="variantClasses"
    @click="onClick"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    label: {
      type: String,
      default: "Button",
    },
    variant: {
      type: String,
      default: "primary",
      validator: (value) => {
        return [
          "primary",
          "secondary",
          "outline-primary",
          "outline-secondary",
          "outline-cancel",
        ].includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      default: "button",
      required: false,
    },
  },
  methods: {
    handleClick() {
      this.onClick();
    },
    onClick() {
      this.$emit("onClick");
    },
  },
  computed: {
    variantClasses() {
      return this.variant.split(" ");
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  width: 100%;
  padding: 9px 24px;

  border: none;

  cursor: pointer;

  transition: all 0.4s ease-out;

  border-radius: 6px;

  &:hover {
    filter: brightness(0.9);
  }

  &:disabled {
    cursor: not-allowed;
    filter: opacity(0.6);
  }
}

.primary {
  background: $orange;
  color: $white;
}

.secondary {
  // Secondary button styles here
}

.outline-cancel {
  background: none;
  color: $neutral-95;
  border: solid 1px $neutral-50;

  &:hover {
    filter: opacity(0.8);
  }
}

.outline-primary {
  // Outline primary button styles here
}

.outline-secondary {
  // Outline secondary button styles here
}
</style>
