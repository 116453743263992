var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.dataRegister.fundo_investimento)?_c('div',{staticClass:"container-items-area"},[_c('div',{staticClass:"row gap-3"},[_c('div',{staticClass:"col-3 d-flex justify-content-start"},[_c('CheckboxGroup',{class:'d-flex flex-column gap-1',attrs:{"options":_vm.tipo_empreendimento,"title":'Tipo de empreendimento',"requiredMessage":_vm.isRequiredMessages.tipo_empreendimento},model:{value:(
            _vm.dataRegister.fundo_investimento.perfil_empreendimentos
              .tipo_empreendimento
          ),callback:function ($$v) {_vm.$set(_vm.dataRegister.fundo_investimento.perfil_empreendimentos
              , "tipo_empreendimento", $$v)},expression:"\n            dataRegister.fundo_investimento.perfil_empreendimentos\n              .tipo_empreendimento\n          "}})],1),_c('div',{staticClass:"col-3 d-flex justify-content-start"},[_c('CheckboxGroup',{class:'d-flex flex-column gap-1',attrs:{"options":_vm.segmento_atuacao,"title":'Segmento de atuação',"requiredMessage":_vm.isRequiredMessages.segmento_atuacao},model:{value:(
            _vm.dataRegister.fundo_investimento.perfil_empreendimentos
              .segmento_atuacao
          ),callback:function ($$v) {_vm.$set(_vm.dataRegister.fundo_investimento.perfil_empreendimentos
              , "segmento_atuacao", $$v)},expression:"\n            dataRegister.fundo_investimento.perfil_empreendimentos\n              .segmento_atuacao\n          "}})],1)]),_c('h3',{staticClass:"content-title"},[_vm._v("Porte da obra")]),_c('div',{staticClass:"row gap-3 mb-4"},[_c('div',{staticClass:"col-3 d-flex justify-content-start"},[_c('Select',{attrs:{"id":'porte_obra',"name":'porte_obra',"label":'Tamanho',"options":_vm.porte_obraOptions,"value":_vm.dataRegister.fundo_investimento.perfil_empreendimentos.porte_obra
              .tamanho[0],"requiredMessage":_vm.isRequiredMessages.tamanho},on:{"input":_vm.updatePorteObraOption}})],1),_c('div',{staticClass:"col-3 d-flex justify-content-start"},[_c('Select',{attrs:{"id":'num_unidades',"name":'num_unidades',"label":'N˚ de unidades',"options":_vm.num_unidadesOptions,"value":_vm.dataRegister.fundo_investimento.perfil_empreendimentos.porte_obra
              .num_unidades[0],"requiredMessage":_vm.isRequiredMessages.num_unidades},on:{"input":_vm.updateNumUnidadesOption}})],1)]),_c('h3',{staticClass:"content-title"},[_vm._v("Região de atuação")]),_c('RegionComponent',{ref:"regionRef",attrs:{"valuesRegions":_vm.dataRegister.fundo_investimento.perfil_empreendimentos.regiao_atuacao}})],1):(_vm.dataRegister.incorporadora)?_c('div',{staticClass:"container-items-area"},[_c('div',{staticClass:"row gap-3"},[_c('div',{staticClass:"col-3 d-flex justify-content-start"},[_c('CheckboxGroup',{class:'d-flex flex-column gap-1',attrs:{"options":_vm.tipo_empreendimento,"title":'Tipo de empreendimento',"requiredMessage":_vm.isRequiredMessages.tipo_empreendimento},model:{value:(
            _vm.dataRegister.incorporadora.perfil_empreendimentos
              .tipo_empreendimento
          ),callback:function ($$v) {_vm.$set(_vm.dataRegister.incorporadora.perfil_empreendimentos
              , "tipo_empreendimento", $$v)},expression:"\n            dataRegister.incorporadora.perfil_empreendimentos\n              .tipo_empreendimento\n          "}})],1),_c('div',{staticClass:"col-3 d-flex justify-content-start"},[_c('CheckboxGroup',{class:'d-flex flex-column gap-1',attrs:{"options":_vm.segmento_atuacao,"title":'Segmento de atuação',"requiredMessage":_vm.isRequiredMessages.segmento_atuacao},model:{value:(
            _vm.dataRegister.incorporadora.perfil_empreendimentos.segmento_atuacao
          ),callback:function ($$v) {_vm.$set(_vm.dataRegister.incorporadora.perfil_empreendimentos, "segmento_atuacao", $$v)},expression:"\n            dataRegister.incorporadora.perfil_empreendimentos.segmento_atuacao\n          "}})],1)]),_c('h3',{staticClass:"content-title"},[_vm._v("Porte da obra")]),_c('div',{staticClass:"row gap-3 mb-4"},[_c('div',{staticClass:"col-3 d-flex justify-content-start"},[_c('Select',{attrs:{"id":'porte_obra',"name":'porte_obra',"label":'Tamanho',"options":_vm.porte_obraOptions,"value":_vm.dataRegister.incorporadora.perfil_empreendimentos.porte_obra
              .tamanho[0],"requiredMessage":_vm.isRequiredMessages.tamanho},on:{"input":_vm.updatePorteObraOption}})],1),_c('div',{staticClass:"col-3 d-flex justify-content-start"},[_c('Select',{attrs:{"id":'num_unidades',"name":'num_unidades',"label":'N˚ de unidades',"options":_vm.num_unidadesOptions,"value":_vm.dataRegister.incorporadora.perfil_empreendimentos.porte_obra
              .num_unidades[0],"requiredMessage":_vm.isRequiredMessages.num_unidades},on:{"input":_vm.updateNumUnidadesOption}})],1)]),_c('h3',{staticClass:"content-title"},[_vm._v("Região de atuação")]),_c('RegionComponent',{ref:"regionRef",attrs:{"valuesRegions":_vm.dataRegister.incorporadora.perfil_empreendimentos.regiao_atuacao}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }